#ocr_page .subpage_nav li:not(:last-child):after {
    content: "|";
    font-size: 16px;
    color: #cdcbcb;
    padding-left: 20px;
}

#ocr_page .subpage_nav li {
    margin-left: 10px;
    margin-right: 10px;
}

#ocr_page .subpage_nav li a {
    font-size: 16px;
    font-weight: bold;
    color: #0053d7 !important;
}

#ocr_page .subpage_nav li a:hover {
    text-decoration: underline !important;
}

#ocr_page_pan .title {
    text-align: center;
}

#ocr_page_pan .form {
    width: 400px;
    margin: auto;
    margin-top: 20px;
}

#ocr_page_pan .api_response {
    margin: auto;
    width: 500px;
}

#ocr_page_pan .api_response_content {
    margin-top: 20px;
}