#home_page .header h2 {
    color: #535356;
}

#home_page .link {
    text-decoration: none;
    height: 50px;
    width: 200px;
    display: inline-block !important;
}


#home_page .link_text {
    height: 100%;
    width: 100%;
    border: 1px solid rgb(218, 217, 217);
    border-radius: 5px;
    padding-top: 10px;
    margin: auto;
    font-size: 18px;
    text-align: center;
}

#home_page .link_text:hover {
    box-shadow: 0px 1px 5px 0px grey;
}